<template>
  <div id="nav">
    <router-link :to="{name: 'EventList'}">Events</router-link> |
    <router-link :to="{name: 'EventCreate'}">New event</router-link> |
    <router-link :to="{name: 'EventVuex'}">Event by vuex</router-link>
  </div>
  <router-view />
</template>

<script>
export default {

  name: 'NavBar',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>