import axios from 'axios'

const apiClient = axios.create({
    // baseURL: 'http://localhost:8000/api',
    baseURL: 'http://localhost:3000',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {
    getEvents(){
        return apiClient.get('/events')
    },

    getEvent(id){
        return apiClient.get('/events/'+id)
    },

    createEvent(data){
        return apiClient.post('/events/', data)
    }
}