<template>
  <div>
    <h1>Create an event</h1>
    <hr />
    <h2>{{ user.name }}</h2>
    <h3>This event was created by: {{ user.id }}</h3>
    <h4>There are {{ catLength }} categories</h4>
    <h5>{{ getEventById(2) }}</h5>
    <hr />
    <form @submit.prevent="sendForm">
      <BaseSelect
        :options="categories"
        v-model="event.category"
        label="Select a category"
      />

      <fieldset>
        <legend>Name & describe your event</legend>

        <BaseInput
          v-model="event.title"
          label="Title"
          type="text"
          error="This input has an error!"
        />

        <BaseInput
          v-model="event.description"
          label="Description"
          type="text"
        />
      </fieldset>

      <fieldset>
        <legend>Where is your event?</legend>

        <BaseInput v-model="event.location" label="Location" type="text" />
      </fieldset>

      <fieldset>
        <legend>Pets</legend>
        <p>Are pets allowed?</p>
        <div>
          <BaseRadioGroup
            v-model="event.pets"
            name="pets"
            :options="petOptions"
          />
        </div>
      </fieldset>

      <fieldset>
        <legend>Extras</legend>
        <div>
          <BaseCheckbox v-model="event.extras.catering" label="Catering" />
        </div>

        <div>
          <BaseCheckbox v-model="event.extras.music" label="Live music" />
        </div>
      </fieldset>

      <button type="submit">Submit</button>
    </form>
    <pre>{{ event }}</pre>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BaseInput from '@/components/Forms/BaseInput.vue'
import BaseSelect from '@/components/Forms/BaseSelect.vue'
import BaseCheckbox from '@/components/Forms/BaseCheckbox.vue'
import BaseRadioGroup from '@/components/Forms/BaseRadioGroup.vue'
import EventService from '@/services/EventService.js'

export default {
  data() {
    return {
      // categories: [
      //   'sustainability',
      //   'nature',
      //   'animal welfare',
      //   'housing',
      //   'education',
      //   'food',
      //   'community',
      // ],
      event: {
        category: '',
        title: '',
        description: '',
        location: '',
        pets: 1,
        extras: {
          catering: false,
          music: false,
        },
      },
      petOptions: [
        { label: 'Yes', value: 1 },
        { label: 'No', value: 0 },
      ],
    }
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseCheckbox,
    BaseRadioGroup,
  },
  computed: {
    getEvent() {
      return this.$store.getters.getEventById
    },
    catLength() {
      return this.$store.getters.catLength
    },
    ...mapGetters(['getEventById']),
    ...mapState(['user', 'categories']),
  },
  methods: {
    sendForm() {
      EventService.createEvent(this.event)
        .then((response) => {
          console.log('Response', response)
        })
        .catch((error) => {
          console.log('Error', error)
        })
    },
  },
}
</script>

<style lang="css" scoped>
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-size: 28px;
  font-weight: 700;
  margin-top: 20px;
}
</style>
