import { createStore } from 'vuex'

export default createStore({
  state: {
    user: { id: 'abc123', name: 'Welington José Miyazato' },
    todos: [
      { id: 1, text: '...', done: true },
      { id: 2, text: '...', done: false },
      { id: 3, text: '...', done: true },
      { id: 4, text: '...', done: false }
    ],
    events: [
      { id: 1, title: '...', organizer: '...' },
      { id: 2, title: '...', organizer: '...' },
      { id: 3, title: '...', organizer: '...' },
      { id: 4, title: '...', organizer: '...' }
    ],
    categories: ['Azul', 'Branca', 'Cinza', 'Verde', 'Vermelho', 'Roxo'],
  },
  mutations: {},
  actions: {},
  modules: {},
  getters: {
    catLength: (state) => {
      return state.categories.length
    },
    doneTodos: (state) => {
      return state.todos.filter((todo) => todo.done)
    },
    activeTodosCount: (state) => {
      return state.todos.filter((todo) => !todo.done).length
    },
    getEventById: state =>  id => {
      return state.events.find(event => event.id === id)
    }
  },
})
